import { gql } from '@apollo/client';

const ORDER_DATA = gql`
  fragment OrderData on Order {
    id
    amount
    term
    startDate
    status
    number
    factor
    formattedFactor
    formattedFirstPayment
    formattedPayment
    formattedPaymentTerm
    formattedAmount
    currency
    spiffMode
    spiffAmount
    creditAssessment {
      loanDecision
    }
    spiffRate
    isCustomSchedule
    formattedSpiffAmount
    formattedFee
    formattedFeePercentage
    formattedCustomerApplicationFee
    formattedVendorApplicationFee
    formattedTerm
    formattedSummaryTotal
    endDate
    approvedAt
    commencedAt
    loanDecision
    canUploadFundingInvoice
    formattedCreatedAt
    createdAt
    agreementSigned
    emailHistory
    billingFrequency
    paymentTerm
    state
    vendorState
    message
    validUntil
    validForDays
    orderProposals {
      id
      uuid
      number
      sortOrder
      title
      amount
      orderType
      billingFrequency
      paymentTerm
      term
      startDate
      endDate
      useVartanaFinancing
      isSelected
      blindDiscount
      spiffRate
      isCustomSchedule
      formattedBuyerInterestRate
      offeredBuyerInterestPercentage
      applyOfferedBuyerInterestRate
      vendorDocs {
        id
        type
        url
        filename
      }
    }
    vendorSummary
    paymentType
    company {
      id
      number
      name
      businessName
      maxUnits
      entityType
      buyerRelationToVendor
      buyerMinAllowedOrderAmount
      formattedBuyerMinAllowedOrderAmount
      availablePaymentMethods
      invoiceEnabled
      primaryUser {
        number
        firstName
        lastName
        email
        phone
        formattedPhone
        formattedTitle
        title
      }
      creditCheck {
        number
        loanDecision
        expired
        pgRequired
        conditionalToCcg
        corporateGuarantor {
          primaryUser {
            email
            firstName
            lastName
            phone
          }
        }
      }
      seller {
        name
        number
        resellerPermissions
        uploadVendorDocs
        testMode
        product {
          allowBuyersToUploadPo
          multipleCountriesEnabled
          enabledCountries
          enabledCurrencies
          showRateToVendor
        }
      }
    }
    userDocuments {
      id
      type
      title
      url
      createdAt
      formattedCreatedAt
      document
    }
    orderItems {
      name
      number
      description
      quantity
      formattedUnitPrice
      amount
      formattedAmount
    }
    account {
      paymentSchedule {
        paymentScheduleItems {
          dueDate
          formattedPayment
          status
          invoice {
            number
            invoicePayments {
              id
              payment {
                number
              }
            }
          }
        }
      }
    }
    user {
      fullName
      firstName
      lastName
      email
      phone
      title
    }
    paymentsCollectedOverPredictedPayments
    encodedAgreement
    canCancel
    updatedAt
    hideOrderDetailsForCollaborator
    acceptanceMode
    milestones {
      number
      vendorMilestoneStatus
    }
    anyPendingInvoicesOnMilestone
    poNumber
  }
`;

export const RESELLER_DETAILS = gql`
  fragment ResellerDetails on Order {
    resellerCreatedOrder
    resellers {
      name
      number
      resellerInvitationStatus
    }
  }
`;

export const GET_ORDER_OWNER = gql`
  query getOrderOwner(
    $orderNumber: String
    $crmOpportunityId: String
    $companyNumber: String!
  ) {
    orderBy(number: $orderNumber, crmOpportunityId: $crmOpportunityId) {
      hideOrderDetailsForCollaborator
      secondaryOwnerFor(companyNumber: $companyNumber) {
        firstName
        lastName
        email
      }
      payoutAmount(companyNumber: $companyNumber)
      formattedPayoutAmount(companyNumber: $companyNumber)
    }
  }
`;

export const GET_NEW_ORDER_DETAILS = gql`
  query getNewOrderDetails($number: String!) {
    newOrderDetails(number: $number)
  }
`;

export const GET_ORDER_BY_NUMBER = gql`
  ${ORDER_DATA}
  ${RESELLER_DETAILS}
  query getOrderByNumber($number: String!) {
    order(number: $number) {
      ...OrderData
      ...ResellerDetails
      canRequestBuyout
      formattedBuyerInterestRate
    }
  }
`;

export const GET_ORDER_BY = gql`
  ${RESELLER_DETAILS}
  query getOrderBy($crmOpportunityId: String, $number: String) {
    orderBy(crmOpportunityId: $crmOpportunityId, number: $number) {
      id
      number
      amount
      formattedAmount
      currency
      startDate
      endDate
      term
      spiffMode
      spiffAmount
      spiffRate
      formattedSpiffAmount
      paymentTerm
      currentStepDetails
      creditAssessment {
        loanDecision
      }
      isCustomSchedule
      formattedBuyerInterestRate
      formattedFirstPayment
      formattedCustomerApplicationFee
      formattedTerm
      formattedSummaryTotal
      loanDecision
      state
      status
      validForDays
      validUntil
      vendorState
      emailHistory
      updatedAtFormatted
      formattedPaymentTerm
      formattedCreatedAt
      billingFrequency
      canUploadFundingInvoice
      hideOrderDetailsForCollaborator
      createdAt
      orderItems {
        name
        description
        formattedUnitPrice
        amount
        formattedAmount
      }
      ...ResellerDetails
      canRequestBuyout
      company {
        id
        name
        entityType
        number
        invoiceEnabled
        buyerRelationToVendor
        availablePaymentMethods
        primaryUser {
          number
          firstName
          lastName
          email
          phone
          formattedPhone
          formattedTitle
          title
        }
        creditCheck {
          number
          approvedCreditTermsFormatted
          maxTerm
          applicationRequired
          loanDecision
          updatedAtFormatted
          expired
          allowedBillingFrequencies
          allowedPaymentTerms
          formattedAllowedPaymentTerms
          pgRequired
          conditionalToCcg
          corporateGuarantor {
            primaryUser {
              email
              firstName
              lastName
              phone
            }
          }
        }
        seller {
          name
          number
          resellerPermissions
          product {
            showRateToVendor
          }
        }
        invoiceEnabled
      }
      userDocuments {
        id
        type
        title
        url
        filename
        createdAt
        formattedCreatedAt
        document
      }
      orderProposals {
        id
        uuid
        number
        sortOrder
        spiffRate
        title
        amount
        orderType
        billingFrequency
        blindDiscount
        paymentTerm
        term
        startDate
        endDate
        useVartanaFinancing
        isSelected
        isCustomSchedule
        formattedBuyerInterestRate
        offeredBuyerInterestPercentage
        applyOfferedBuyerInterestRate
        vendorDocs {
          id
          type
          url
          filename
        }
      }
      user {
        fullName
        firstName
        lastName
        email
        phone
        title
      }
      milestones {
        number
        vendorMilestoneStatus
      }
      vendorSummary
      updatedAt
      acceptanceMode
      anyPendingInvoicesOnMilestone
      poNumber
    }
  }
`;

export const GET_ORDER_AGREEMENT_PREVIEW = gql`
  query getOrderAgreementPreview($number: String!) {
    order(number: $number) {
      previewAgreement
    }
  }
`;

export const GET_ORDERS = gql`
  query getOrders(
    $first: Int
    $after: String
    $number: String
    $company: String
    $vendorName: String
    $status: String
    $decision: String
    $isResellerUser: Boolean!
    $currency: String
  ) {
    sellerOrders(
      first: $first
      after: $after
      number: $number
      company: $company
      vendorName: $vendorName
      status: $status
      decision: $decision
      currency: $currency
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          number
          company {
            name
            number
            buyerRelationToVendor @include(if: $isResellerUser)
            seller @include(if: $isResellerUser) {
              name
              number
              resellerPermissions
            }
          }
          canUploadFundingInvoice
          hideOrderDetailsForCollaborator
          anyPendingInvoicesOnMilestone
          milestones {
            number
            vendorMilestoneStatus
          }
          formattedAmount
          formattedFee
          formattedTotalFees
          formattedFeePercentage
          formattedPaymentTerm
          amount
          currency
          discount
          term
          formattedTerm
          status
          vendorState
          updatedAt
          loanDecision
          validForDays
          validUntil
          user {
            id
            fullName
            firstName
            lastName
            email
            phone
            title
            userRole {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const CREATE_ORDER_WITH_PROPOSALS = gql`
  mutation createOrderWithProposals(
    $companyNumber: String!
    $orderProposals: [InputOrderProposals!]!
    $authorizedSigner: InputAuthorizedSigner!
    $message: String
    $crmOpportunityId: String
    $crmOpportunityStage: String
    $crmOpportunityProbability: Float
    $crmOpportunityCloseDate: ISO8601Date
    $payoutInformation: String
    $payoutRecipients: [String!]
    $resellers: [InputReseller!]
  ) {
    createOrderWithProposals(
      companyNumber: $companyNumber
      message: $message
      payoutInformation: $payoutInformation
      payoutRecipients: $payoutRecipients
      resellers: $resellers
      orderProposals: $orderProposals
      crmOpportunityId: $crmOpportunityId
      crmOpportunityStage: $crmOpportunityStage
      crmOpportunityProbability: $crmOpportunityProbability
      crmOpportunityCloseDate: $crmOpportunityCloseDate
      authorizedSigner: $authorizedSigner
    ) {
      id
      number
    }
  }
`;

export const UPDATE_ORDER_WITH_PROPOSALS = gql`
  mutation updateOrderWithProposals(
    $orderNumber: String!
    $orderProposals: [InputOrderProposals!]!
  ) {
    updateOrderWithProposals(orderNumber: $orderNumber, orderProposals: $orderProposals) {
      id
      number
    }
  }
`;

export const UPDATE_ORDER_DOCUMENTATION_FEE = gql`
  mutation updateDocumentationFee($orderNumber: String!, $selectedPayer: String!) {
    updateDocumentationFee(orderNumber: $orderNumber, selectedPayer: $selectedPayer) {
      id
      number
    }
  }
`;

export const SEND_PARTNER_CHECKOUT_LINK = gql`
  mutation sendPartnerCheckoutLink(
    $companyId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
  ) {
    sendPartnerCheckout(
      companyId: $companyId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
    ) {
      id
    }
  }
`;

export const SEND_CHECKOUT = gql`
  mutation sendCheckout(
    $companyNumber: String
    $crmOpportunityId: String
    $number: String
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $message: String!
  ) {
    sendCheckout(
      companyNumber: $companyNumber
      crmOpportunityId: $crmOpportunityId
      number: $number
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      message: $message
    ) {
      id
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($number: String!, $cancelReasons: [String!]!) {
    cancelOrder(number: $number, cancelReasons: $cancelReasons) {
      number
      status
      canCancel
    }
  }
`;

export const APPROVE_CHECKOUT = gql`
  mutation approveCheckout($number: String!) {
    approveCheckout(number: $number) {
      number
      status
    }
  }
`;

export const PREVIEW_ORDER_DOCUMENT = gql`
  mutation previewOrderDocuments($number: String!) {
    previewOrderDocuments(number: $number) {
      encodedDocuments
      number
    }
  }
`;

export const PREVIEW_PAYOFF_STATEMENT = gql`
  mutation previewPayoffStatement($number: String!) {
    previewPayoffStatement(number: $number) {
      payoffStatementDocument
    }
  }
`;

export const SWITCH_ORDER_PROPOSAL = gql`
  mutation switchOrderProposal($proposalNumber: String!) {
    switchOrderProposal(proposalNumber: $proposalNumber) {
      useVartanaFinancing
      buyerSummary
    }
  }
`;

export const REASSIGN_ORDER_ACCOUNT_OWNER = gql`
  mutation changeOrderAccountExecutive($number: String!, $userNumber: String!) {
    changeOrderAccountExecutive(number: $number, userNumber: $userNumber) {
      id
    }
  }
`;

export const GET_VENDOR_SIGNATURE_URL = gql`
  query getOrderByNumber($number: String!) {
    order(number: $number) {
      vendorSignatureUrl
    }
  }
`;

export const ARCHIVE_VENDOR_DOCS = gql`
  mutation archiveVendorDocs($documentIds: [ID!]!, $orderNumber: String!) {
    archiveVendorDocs(documentIds: $documentIds, orderNumber: $orderNumber) {
      id
    }
  }
`;

export const FINANCING_INFORMATION = gql`
  query financingInformation(
    $companyNumber: String!
    $amount: Float!
    $paymentTerm: Int!
    $billingFrequency: String
    $percentageBlindDiscount: Float
    $dollarBlindDiscount: Float
    $isDollar: Boolean
    $useVartanaFinancing: Boolean!
    $selectedPaymentOption: String!
    $spiffRate: Float!
    $term: Int!
    $orderNumber: String
    $offeredBuyerInterestRate: Float
    $applyOfferedBuyerInterestRate: Boolean
  ) {
    financingInformation(
      companyNumber: $companyNumber
      amount: $amount
      paymentTerm: $paymentTerm
      billingFrequency: $billingFrequency
      percentageBlindDiscount: $percentageBlindDiscount
      dollarBlindDiscount: $dollarBlindDiscount
      isDollar: $isDollar
      useVartanaFinancing: $useVartanaFinancing
      selectedPaymentOption: $selectedPaymentOption
      spiffRate: $spiffRate
      term: $term
      orderNumber: $orderNumber
      offeredBuyerInterestRate: $offeredBuyerInterestRate
      applyOfferedBuyerInterestRate: $applyOfferedBuyerInterestRate
    )
  }
`;

export const RESEND_CORPORATE_GUARANTOR_SIGNATURE_LINK = gql`
  mutation resendCorporateGuarantorSignatureLink($number: String!) {
    resendCorporateGuarantorSignatureLink(number: $number) {
      id
      number
    }
  }
`;
